var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Update Profile",
        icon: "mdi-account",
        "btn-label": "Save Profile",
        "btn-icon": "mdi-account-check"
      },
      on: { "section-save": _vm.saveProfile }
    },
    [
      _c("psi-form-section", {
        ref: "update-profile",
        attrs: {
          value: _vm.profile,
          name: "date-input-data",
          label: "",
          fields: _vm.profileFields,
          color: "secondary"
        },
        on: { input: _vm.update }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }