<template>
    <psi-form-layout-section
        title="Update Profile"
        icon="mdi-account"
        btn-label="Save Profile"
        btn-icon="mdi-account-check"
        @section-save="saveProfile"
    >
        <psi-form-section
            ref="update-profile"
            :value="profile"
            name="date-input-data"
            label=""
            :fields="profileFields"
            color="secondary"
            @input="update"
        ></psi-form-section>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "update-profile",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            profile: {},
            profileFields: [
                {
                    type: "text",
                    name: "name",
                    key: "name",
                    label: "Name",
                    icon: this.$config("icons.name"),
                    required: true,
                },
                {
                    type: "text",
                    name: "email",
                    key: "email",
                    label: "Email",
                    format: "email",
                    icon: this.$config("icons.email"),
                    required: true,
                },
                {
                    type: "text",
                    name: "phone",
                    key: "phone",
                    label: "Phone",
                    format: "tel",
                    icon: this.$config("icons.phone"),
                    required: true,
                },
            ],
        };
    },
    computed: {},
    watch: {
        user: {
            immediate: true,
            handler(val) {
                if (Object.keys(val).length > 0) {
                    const {
                        name,
                        email,
                        contact: { phone },
                    } = val;
                    this.profile = { name, email, phone };
                }
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update() {},
        saveProfile() {
            console.log(this.$refs["update-profile"].validate());
        },
    },
};
</script>

<style scoped>
</style>